import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate } from '@angular/router';
import { PromanStateService } from '@frontend/shared/services/proman-state.service';
import { UserResolver } from '../app-resolvers';
import { Store } from '@ngrx/store';
import { getCurrUser } from '@proman/store/curr-user';
import { AppLoader } from '@proman/services/app-loader.service';
import { Router } from '@angular/router';
import { CurrUser } from '@proman/interfaces/object-interfaces';
import { PromanDialogService } from '@proman/dialog/proman-dialog.service';
import { LoginVerificationDialogComponent } from '@frontend/shared/components/login-verification-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class GuardsSelfAuthorizationService implements CanActivate {
  currUser: CurrUser;
  constructor(
    private PromanState: PromanStateService,
    private Router: Router,
    private Dialog: PromanDialogService,
    private AppLoader: AppLoader,
    private store: Store,
    @Inject(UserResolver) private userResolver: UserResolver,
  ) {
  }

  async canActivate(
    route: ActivatedRouteSnapshot,
  ): Promise<boolean> {
    await this.userResolver.resolve();

    this.store.select(getCurrUser).subscribe((value) => this.currUser = value);

    const destinationId = route.params?.employeeId;
    const isSelf = this.currUser.person.id === parseInt(destinationId);
    if (isSelf) {
      this.AppLoader.remove();
      return this.Dialog.open(LoginVerificationDialogComponent, {}, { disableClose: true }).then((result: number) => {
        if (result > 5) {
          return true;
        } else {
          if (this.Router.url.match('/')) {
            this.PromanState.to('/employees');
          }
        }
      })
    } else {
      return true;
    }
  }
}
